@import "/src/assets/scss/color.scss";
@import "/src/assets/scss/customize-bootstrap.scss";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "helvethaica";
  src: url("/assets/fonts/DBHelvethaicaXv3.2.ttf");
}

@font-face {
  font-family: "helvethaicathin";
  src: url("/assets/fonts/DBHelvethaicaXThin.ttf");
}

@font-face {
  font-family: "helvethaicali";
  src: url("/assets/fonts/DBHelvethaicaXLi.ttf");
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
}

body {

  div,
  p,
  label,
  span,
  .form-control,
  .btn {
    font-family: helvethaica, Roboto, "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
}

.p-component,
.p-component * {
  font-family: helvethaica, Roboto, "Helvetica Neue", sans-serif;

  div,
  a,
  span,
  i,
  label,
  button {
    font-size: 20px;
  }
}

.text-small-responsive {
  @media (max-width: 575.98px) {
    font-size: 18px !important;
  }
}

.remark-text {
  font-size: 18px !important;
}

.title-topic {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.title-header,
.icon-header {
  font-size: 24px !important;
}

.icon-img img {
  height: 18px;
}

.wrapper {
  height: 100vh;
  display: flex;

  /* Direction of the items, can be row or column */
  flex-direction: column;
}

.content {
  flex: 1;
}

.custom-input {
  input {
    height: 2.5rem;
    font-size: 20px;
  }

  .btn-next {
    //height: 3rem;
    font-size: 20px;
    color: white;
  }

  .register-info {
    font-size: 20px;
  }

  .register-normal {
    font-size: 22px;
  }

  .under-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  }


}

.btn {
  font-size: 1.5rem;
}

.color-black {
  width: 10rem;
  background-color: black;
  color: white;
}

.color-black:hover {
  opacity: 80%;
  width: 10rem;
  background-color: black;
  color: white;
}


.color-orange {
  width: 10rem;
  background-color: orange;
  color: white;
}

.title-headline {
  color: orange;
  font-size: 22px;
}

.title-headline-box {
  color: black;
  font-size: 24px;
}

.home-box-padding {
  padding: 15px;
}

.see-more-size {
  font-size: 22px;
  color: black;
  padding-right: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';



body {
  overscroll-behavior-y: contain;
  overscroll-behavior: contain;

}


.carousel-indicators li {
  background-color: #5f5858 !important;
}




.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
}


.input-password {
  -webkit-text-security: disc;
}

.bg {
  // background-color: #EEEEEE;
  background-image: url("assets/image/background.png");
  min-width: 100%;
  min-height: 100%;
}

.bg-layout {
  top: 0;
  left: 0;
  z-index: 0;
  /* Preserve aspet ratio */
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.form-control.large-font {
  font-size: 1.75rem;
  height: calc(1.2em + 0.5rem + 2px);
  padding: 0.175rem 0.75rem;
}

.body-content {
  background-color: #EEEEEE;
}

.cursor-pointer {
  cursor: pointer;
}

.primng-icon-size {
  width: 23px;
}

.export-button {
  color: #00AF5C;
  text-align: center;
}

.export-button i {
  font-size: 1.5rem;
}

.button-display-none {
  display: none !important;
}

.box-container {
  display: flex;
  justify-content: center;

  .box-no-border {
    width: 60%;

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .box {
    background-color: white;
    width: 60%;
    min-height: 92vh;
    border: 1px solid #dee2e6;

    @media (max-width: 575.98px) {
      width: 100%;
    }

    .custom-card {
      width: 500px;
      margin: 0 1rem;

      @media (max-width: 575.98px) {
        max-width: 300px;
        margin: 15px;
      }
    }
  }

}

.dropzone {
  border: 1px solid #ccc !important;
  border-radius: 0.5rem;
}

// .dropzone.disable {
//   padding: 0;
//   border: 0;
// }

.dropzone.disable .dz-preview {
  cursor: pointer;
  margin: 0 1rem 1rem 0;
}