$primary: $color-primary;
$secondary: $color-secondary;
$success: $color-success;
$info: $color-info;
$warning: $color-warning;
$danger: $color-danger;

$ng-select-highlight: rgba($color-primary, 0.5);
$ng-select-box-shadow: inset 0 1px 1px rgba($color-primary, 0.075),
  0 0 0 3px rgba($color-primary, 0.1) !default;
$ng-select-selected: rgba($color-primary, 0.3);
$ng-select-marked: rgba($color-primary, 0.1);

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
